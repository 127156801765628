import React, {useEffect} from "react";
import {Button, Card, Col, Form, OverlayTrigger, Row, Tooltip,} from "react-bootstrap";
import "../../styles/sharedProfile.css";
import "../../styles/profile.css";
import "react-image-crop/dist/ReactCrop.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCamera, faEdit} from "@fortawesome/free-solid-svg-icons";
import ImageModal from "./imageModal";
import {default as Editpost} from "../navigation/icons/edit_post.svg";
import {default as VerifiedIcon} from "../../images/vikaaria_verified_badge.svg"
import Backbar from "../mobile/backbar";
import {Link} from "react-router-dom";
import EditProfileModal from "../func-components/editProfileModal";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {setCurrentTeacher} from "../../actions/authActions";
import store from "../../store"
import {toast} from "react-toastify";

const ProfileForm = (props) => {
    const {
        data,
        teacher,
        imgUrl,
        file,
        crop,
        inputFileRef,
        onImgChange,
        onCropChange,
        onCropComplete,
        handleImgUpload,
        handleFileClick,
        handleEdit,
        onImageLoaded,
        isOpen,
        handleCloseChange,
        handleEditBot,
        editTooltip,
        mobile,
        setRender,
        language,
        handleDeleteAcc,
        obligatoryTooltip,
        hasEdited,
        editProfileIsOpen,
        handleEditProfileCloseChange,
    } = props;

    const isUnlocked = () => {
        return process.env.REACT_APP_ENABLE_OP;
    }
    const isEmptyObject = (obj) => {
        if (typeof obj === "undefined" || typeof obj === "null") return true;
        return Object.keys(obj).length === 0;
    };

    const UserAvatar = imgUrl + data.image;

    const verifyWithOP = () => {
        axios
            .get(`/oauth/authorize?email=${teacher.email}`)
            .then(res => {
                window.location.href = res.data.authUrl;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // Function to get query parameters from the URL
    function getQueryParams() {
        const params = {};
        const queryString = window.location.search.substring(1);
        const queryArray = queryString.split('&');
        queryArray.forEach(param => {
            const [key, value] = param.split('=');
            params[key] = decodeURIComponent(value);
        });
        return params;
    }

    function clearQueryParams() {
        const url = window.location.origin + window.location.pathname;
        window.history.replaceState({}, document.title, url);
    }

    useEffect(() => {
        // Check the status and handle accordingly
        const queryParams = getQueryParams();
        if (queryParams.status === 'success') {
            console.log('Verification was successful');
            toast.success("Verification was successful");
            // Update user
            axios
                .get("/api/shared/update/token")
                .then(res => {
                    const {token} = res.data;
                    localStorage.setItem("jwtToken", token);
                    setAuthToken(token);
                    const decoded = jwt_decode(token);
                    store.dispatch(setCurrentTeacher(decoded));
                })
                .catch(e => {
                    console.error("Could not update token", e);
                })
            clearQueryParams();
        } else if (queryParams.status === 'error') {
            console.error('Verification failed:', queryParams.message);
            // Handle error
            toast.error(`Verification failed: ${queryParams.message}`);
            clearQueryParams();
        }
    }, []);

    return (
        <Row
            className="f-col"
            style={{
                margin: "0",
                padding: "0",
            }}
        >
            <Col
                style={{
                    margin: "0",
                    padding: "0",
                }}
            >
                <Card
                    className={mobile ? "" : "mb-3 mt-3"}
                    style={{
                        borderRadius: "14px",
                        border: "none",
                        boxShadow: "0px 0px 24px 0px rgba(13, 17, 77, 0.10)",
                        marginTop: "1em",
                    }}
                >
                    {mobile && (
                        <Backbar
                            padding={true}
                            sticky={mobile}
                            desktop={!mobile}
                            setrender={setRender}
                            title={language.profile}
                            identityVerified={teacher?.identityVerified}
                        />
                    )}
                    {!mobile && (!hasEdited || !teacher?.identityVerified) ? (
                        <EditProfileModal
                            isOpen={editProfileIsOpen}
                            handleCloseChange={handleEditProfileCloseChange}
                            language={language}
                        />
                    ) : (
                        <></>
                    )}
                    <Form>
                        <Row className="f-col">
                            {teacher.typeOfUser === "user" ? (
                                <>
                                    <Col md={12} lg={6}>
                                        <Card.Body className="">
                                            <Card.Title>
                                                <h4 style={{fontSize: "20px", display: "flex"}}>
                                                    {language.basicinfo}
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{show: 250, hide: 400}}
                                                        overlay={editTooltip}
                                                    >
                                                        <img
                                                            alt=""
                                                            src={Editpost}
                                                            className="edit-icon ml-auto"
                                                            onClick={handleEdit}
                                                            icon={faEdit}
                                                        />
                                                    </OverlayTrigger>
                                                </h4>
                                            </Card.Title>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="firstName">
                                                        <Form.Label className="text-muted lt cBold">
                                                            {language.firstname}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data.firstName}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="lastName">
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.lastname}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data.lastName}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.fi ? "Syntymävuosi" : "Födelseår"}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data?.age}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.address}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data?.address}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="phone">
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.phone}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data.phone}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="email">
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.email}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data.email}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.nativelang}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data?.nativeLanguage}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Form.Group controlId="status" as={Col}>
                                                    <Form.Label className="text-muted lt cBold ">
                                                        {language.fi ? "Tila" : "Status"}
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        className="custom-control"
                                                        placeholder={data.status}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.appLang}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={
                                                                data?.siteLang === 1 ? "Suomi" : "Svenska"
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.regiontext}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={
                                                                data.regions
                                                                    ? data.regions.map(
                                                                        (region, index) =>
                                                                            (index ? " " : "") +
                                                                            (language.fi
                                                                                ? region.namefi
                                                                                : region.name_swe)
                                                                    )
                                                                    : "-"
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Col>
                                                    <Form.File
                                                        accept="image/*"
                                                        ref={inputFileRef}
                                                        label="Profilbild"
                                                        id="image"
                                                        type="file"
                                                        onChange={onImgChange}
                                                        style={{display: "none"}}
                                                    />
                                                    <Button
                                                        onClick={handleFileClick}
                                                        className="mr-2 d-flex flex-row text-center justify-content-center align-items-center"
                                                        variant="imageBtn-reverse"
                                                        block
                                                    >

                                                        <FontAwesomeIcon className="mr-3" icon={faCamera}/>
                                                        {language.changepic}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            {isUnlocked() && (
                                                <Row className="f-col">
                                                    <Col>
                                                        <OverlayTrigger
                                                            placement="auto"
                                                            delay={{show: 250, hide: 400}}
                                                            overlay={
                                                                <Tooltip id="accepted-tooltip" {...props}>
                                                                    {language.verification_hint}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <Button
                                                                onClick={verifyWithOP}
                                                                disabled={teacher?.identityVerified}
                                                                className="mr-2 mt-2 d-flex flex-row text-center justify-content-center align-items-center"
                                                                variant="imageBtn-reverse"
                                                                block
                                                            >
                                                                <img alt="Verify yourself call to action icon"
                                                                     src={VerifiedIcon}
                                                                     style={{
                                                                         width: "20px",
                                                                         marginRight: "16px"
                                                                     }}/>
                                                                <div>{teacher?.identityVerified ? language.user_verified : language.user_verify}</div>
                                                            </Button>
                                                        </OverlayTrigger>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Card.Body>
                                    </Col>
                                    <Col md={12} lg={6}>
                                        <Card.Body>
                                            <Card.Title style={{fontSize: "20px", display: "flex"}}>
                                                {language.additionalinfo}
                                                <OverlayTrigger
                                                    placement="auto"
                                                    delay={{show: 250, hide: 400}}
                                                    overlay={editTooltip}
                                                >
                                                    <img
                                                        src={Editpost}
                                                        className="edit-icon ml-auto"
                                                        onClick={handleEditBot}
                                                        icon={faEdit}
                                                    />
                                                </OverlayTrigger>
                                            </Card.Title>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.languages}
                                                        </Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data?.languages?.map(
                                                                (lang, index) =>
                                                                    (index ? " " : "") +
                                                                    (language.sv ? lang.name : lang.name_fi)
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                {data.status === "Studerande" ||
                                                data.status === "Opiskelija" ? (
                                                    <Col xl={6} lg={12}>
                                                        <Form.Group controlId="occugroup">
                                                            <Form.Label className="text-muted lt cBold ">
                                                                {language.schoolprofile}
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                className="custom-control"
                                                                placeholder={data?.occuGroup?.school}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                ) : (
                                                    <Col xl={6} lg={12}>
                                                        <Form.Group controlId="occugroup">
                                                            <Form.Label className="text-muted lt cBold ">
                                                                {language.schoolprofile}
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                className="custom-control"
                                                                placeholder={data?.occuGroup?.school}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                )}
                                            </Row>
                                            {data.status !== "Studerande" &&
                                            data.status !== "Opiskelija" ? (
                                                <>
                                                    <Row className="f-col">
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="education">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.degree2}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={data.education}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="exYear">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.yearofdegree}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={data.examinationYear}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="f-col">
                                                        {/* {(data.status === "Lärare" && data.status !== "Opettaja") && (
                                                )} */}
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="occugroup">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.qualifications}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    as="textarea"
                                                                    className="custom-control"
                                                                    placeholder={
                                                                        data.qualifications
                                                                            ? data.qualifications.map(
                                                                                (qualification, index) =>
                                                                                    (index ? " " : "") +
                                                                                    (language.fi
                                                                                        ? qualification.name_fi
                                                                                        : qualification.name_swe)
                                                                            )
                                                                            : "-"
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="studies">
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.studies2}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="custom-control"
                                                                    disabled
                                                                    placeholder={data?.occuGroup?.studies}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>

                                                    <Row className="f-col">
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="mainsubject">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.mainsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={data?.mainsubject}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="secondsubject">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.secondsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={data?.secondsubject}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {/* <Row className="f-col">
                                                <Form.Group controlId="studies" as={Col}>
                                                    <Form.Label className="text-muted lt cBold">{language.studies2}</Form.Label>
                                                    <Form.Control className="custom-control" disabled placeholder={data?.occuGroup?.studies} />
                                                </Form.Group>
                                            </Row> */}
                                                    <Row className="f-col">
                                                        <Form.Group controlId="refs" as={Col}>
                                                            <Form.Label className="text-muted lt cBold ">
                                                                {language.eduexp}
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                disabled
                                                                className="custom-control"
                                                                placeholder={data.experience}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </>
                                            ) : (
                                                <>
                                                    <Row className="f-col">
                                                        {/* <Col xl={6} lg={12}>
                                                    <Form.Group controlId="education">
                                                        <Form.Label className="text-muted lt cBold ">{language.degree}</Form.Label>
                                                        <Form.Control disabled className="custom-control" placeholder={data?.education} />
                                                    </Form.Group>
                                                </Col> */}
                                                        <Col /* xl={6} lg={12} */>
                                                            <Form.Group controlId="prfgrp">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.studyingTo}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    as="textarea"
                                                                    className="custom-control"
                                                                    placeholder={
                                                                        data.comingqualifications
                                                                            ? data.comingqualifications.map(
                                                                                (qualification, index) =>
                                                                                    (index ? " " : "") +
                                                                                    (language.fi
                                                                                        ? qualification.name_fi
                                                                                        : qualification.name_swe)
                                                                            )
                                                                            : "-"
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="exYear">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.studyyear}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={data?.occuGroup?.studyYear}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="f-col">
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="mainsubject">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.mainsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={data?.mainsubject}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="secondsubject">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.secondsubject}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    className="custom-control"
                                                                    placeholder={data?.secondsubject}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="f-col">
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="studies">
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.studies}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="custom-control"
                                                                    disabled
                                                                    placeholder={data?.occuGroup?.studies}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xl={6} lg={12}>
                                                            <Form.Group controlId="points">
                                                                <Form.Label className="text-muted lt cBold">
                                                                    {language.studypoints}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    className="custom-control"
                                                                    disabled
                                                                    placeholder={data?.occuGroup?.points}
                                                                    type="text"
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="f-col">
                                                        <Col /* xl={6} lg={12} */>
                                                            <Form.Group controlId="prfgrp">
                                                                <Form.Label className="text-muted lt cBold ">
                                                                    {language.qualifications_student}
                                                                </Form.Label>
                                                                <Form.Control
                                                                    disabled
                                                                    as="textarea"
                                                                    className="custom-control"
                                                                    placeholder={
                                                                        data.qualifications
                                                                            ? data.qualifications.map(
                                                                                (qualification, index) =>
                                                                                    (index ? " " : "") +
                                                                                    (language.fi
                                                                                        ? qualification.name_fi
                                                                                        : qualification.name_swe)
                                                                            )
                                                                            : "-"
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="f-col">
                                                        <Form.Group controlId="refs" as={Col}>
                                                            <Form.Label className="text-muted lt cBold ">
                                                                {language.eduexp}
                                                            </Form.Label>
                                                            <Form.Control
                                                                as="textarea"
                                                                disabled
                                                                className="custom-control"
                                                                placeholder={data.experience}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                </>
                                            )}
                                            <Row className="f-col">
                                                <Col>
                                                    <Link
                                                        className="text-decoration-none"
                                                        to={{
                                                            pathname: "/resetpassword",
                                                            forgotProps: {
                                                                mobile: true,
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            variant="customSuccess"
                                                            className="mt-4 text-decoration-none d-flex flex-row text-center justify-content-center align-items-center"
                                                            block
                                                            size="lg"
                                                        >
                                                            {language.changepass}
                                                        </Button>
                                                    </Link>
                                                    <Button
                                                        variant="customDelete"
                                                        className="mt-2 d-flex flex-row text-center justify-content-center align-items-center"
                                                        onClick={handleDeleteAcc}
                                                        block
                                                        size="lg"
                                                    >
                                                        {language.deleteacc}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Col>
                                </>
                            ) : (
                                <>
                                    <Col>
                                        <Card.Body className="">
                                            <Card.Title>
                                                <h4 style={{fontSize: "20px", display: "flex"}}>
                                                    {language.basicinfo}
                                                    <OverlayTrigger
                                                        placement="auto"
                                                        delay={{show: 250, hide: 400}}
                                                        overlay={editTooltip}
                                                    >
                                                        <img
                                                            alt=""
                                                            src={Editpost}
                                                            className="edit-icon ml-auto"
                                                            onClick={handleEdit}
                                                            icon={faEdit}
                                                        />
                                                    </OverlayTrigger>
                                                </h4>
                                            </Card.Title>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="firstName">
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.firstname}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data.firstName}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="lastName">
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.lastname}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data.lastName}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Form.Group controlId="email" as={Col}>
                                                    <Form.Label className="text-muted lt cBold ">
                                                        {language.email}
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        className="custom-control"
                                                        placeholder={data.email}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="phone">
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.phone}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data.phone}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} lg={12}>
                                                    <Form.Group controlId="grade">
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.grade}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={
                                                                data.grade.length > 0
                                                                    ? data.grade.join(", ")
                                                                    : "-"
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.nativelang}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data?.nativeLanguage}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xl={6} lg={12}>
                                                    <Form.Group>
                                                        <Form.Label className="text-muted lt cBold ">
                                                            {language.languages}
                                                        </Form.Label>
                                                        <Form.Control
                                                            disabled
                                                            className="custom-control"
                                                            placeholder={data?.languages?.map(
                                                                (lang, index) =>
                                                                    (index ? " " : "") +
                                                                    (language.sv ? lang.name : lang.name_fi)
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Form.Group as={Col}>
                                                    <Form.Label className="text-muted lt cBold ">
                                                        {language.appLang}
                                                    </Form.Label>
                                                    <Form.Control
                                                        disabled
                                                        className="custom-control"
                                                        placeholder={
                                                            data?.siteLang === 1 ? "Suomi" : "Svenska"
                                                        }
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="f-col">
                                                <Col>
                                                    <Form.File
                                                        accept="image/*"
                                                        ref={inputFileRef}
                                                        label="Profilbild"
                                                        id="image"
                                                        type="file"
                                                        onChange={onImgChange}
                                                        style={{display: "none"}}
                                                    />
                                                    <Button
                                                        onClick={handleFileClick}
                                                        className="mr-2"
                                                        variant="imageBtn-reverse"
                                                        block
                                                    >
                                                        <FontAwesomeIcon className="mr-3" icon={faCamera}/>
                                                        {language.changepic}
                                                    </Button>
                                                </Col>
                                            </Row>
                                            <Row className="f-col">
                                                <Col>
                                                    <Link
                                                        className="text-decoration-none"
                                                        to={{
                                                            pathname: "/resetpassword",
                                                            forgotProps: {
                                                                mobile: true,
                                                            },
                                                        }}
                                                    >
                                                        <Button
                                                            variant="customSuccess"
                                                            className="mt-4 text-decoration-none"
                                                            block
                                                            size="lg"
                                                        >
                                                            {language.changepass}
                                                        </Button>
                                                    </Link>
                                                    <Button
                                                        variant="customDelete"
                                                        className="mt-2"
                                                        onClick={handleDeleteAcc}
                                                        block
                                                        size="lg"
                                                    >
                                                        {language.deleteacc}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Col>
                                </>
                            )}
                        </Row>
                    </Form>
                </Card>
            </Col>
            <ImageModal
                isOpen={isOpen}
                handleCloseChange={handleCloseChange}
                img={file}
                crop={crop}
                onImageLoaded={onImageLoaded}
                onCropComplete={onCropComplete}
                onCropChange={onCropChange}
                handleImgUpload={handleImgUpload}
                language={language}
            />
        </Row>
    );
};
export default ProfileForm;
