import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {
  activateAllTeachers,
  activateTeacher,
  deactivateAllTeachers,
  deactivateTeacher,
  deleteTeacher,
  getSchoolCodes,
  logoutTeacher,
} from "../../actions/authActions";
import {Button, Card, Col, Container, Dropdown, Row} from "react-bootstrap";
import Sort from "../func-components/sort";
import Spinner from "../func-components/spinner";
import CustomNavbar from "../navigation/customNavbar";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DefaultPhoto from "../../images/avatar_default.png";
import {default as Location} from "../tempjob/icons/location.svg";
import {default as Mail, default as EmailIcon,} from "../tempjob/icons/mail.svg";
import {default as Phone, default as PhoneIcon,} from "../tempjob/icons/phone.svg";
import sv from "../../texts/sv.json";
import fi from "../../texts/fi.json";
import {io} from "socket.io-client";
import {withAlert} from "react-alert";
import {default as EditDots} from "../tempjob/icons/dot_menu.svg";
import {default as RemoveIcon} from "../tempjob/icons/delete_purple.svg";
import {default as EditIcon} from "../navigation/icons/edit_post.svg";
import EditTeacher from "./editTeacher";
import {toast, ToastContainer} from "react-toastify";

import "../../styles/teacherAdmin.css";
import "./teacherAdmin.css";
import SchoolCodePage from "./SchoolCodePage";

class TeacherAdmin extends Component {
    constructor() {
        super();
        this.state = {
            listSort: false,
            teachers: [],
            isLoading: true,
            errors: {},
            sidebar: window.matchMedia("(max-width: 1450px)").matches ? false : true,
            language: {},
            editTeacher: false,
            teacherToEdit: {},
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            grades: [],
            codeInfo: [],
            preschool: false,
        };
    }

    /* TODO: aktivera och avaktivera lärare flyttas under lärar korten */

    componentDidMount() {
        window.scrollTo(0, 0);
        if (this.props.auth.teacher.typeOfUser !== "principal") {
            this.props.history.push("/");
        }

        const data = {
            email: this.props.auth.teacher.email,
        };

        const socket = io();

        socket.on("updateteachers", () => {
            this.getTeachers(data);
        });

        this.getLang(this.props.auth.teacher);

        this.getTeachers(data);
        this.useGetCodes(data).finally();
    }

    useGetCodes = async (data) => {
        const codeInfo = await this.props.getSchoolCodes(data);
        this.setState({codeInfo});
    };

    getTeachers = (data) => {
        axios
            .post("/api/principals/teachers/getall", data)
            .then((res) => {
                this.setState({
                    teachers: res.data.teachers,
                    isLoading: false,
                    preschool: res.data.preschool,
                });
            })
            .catch((err) => console.log(err));
    };

    getLang = (user) => {
        if (user.siteLang === 2) {
            this.setState({language: sv});
        } else if (user.siteLang === 1) {
            this.setState({language: fi});
        } else {
            this.setState({language: fi});
        }
    };

    listOrBlock = (e) => {
        console.log(e.currentTarget.id);
        if (e.currentTarget.id === "block") {
            this.setState({listSort: false});
        }
        if (e.currentTarget.id === "list") {
            this.setState({listSort: true});
        }
    };

    setGrades = (grades) => {
        this.setState({grades: grades.sort()});
    };

    customToggle = React.forwardRef(({children, onClick}, ref) => (
        <a
            href=""
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <img src={EditDots} alt="edit_dots_svg" className=""/>
        </a>
    ));

    handleEdit = (e) => {
        e.preventDefault();
        const email = e.currentTarget.id;
        const data = {
            email: email,
        };
        axios
            .post("/api/teachers/get/teacher", data)
            .then((res) => {
                this.setState({editTeacher: true, teacherToEdit: res.data});
            })
            .catch((err) => console.log(err));
    };

    onChange = (e) => {
        this.setState({[e.target.id]: e.target.value});
    };

    onSubmit = (e) => {
        e.preventDefault();

        const {firstName, lastName, email, phone, grades} = this.state;
        const tte = this.state.teacherToEdit;
        const data = {
            firstName: firstName || tte.firstName,
            lastName: lastName || tte.lastName,
            email: email || tte.email,
            phone: phone || tte.phone,
            oldEmail: tte.email,
            grades: grades || tte.grades,
        };
        console.log("data: ", data);
        /* this.setState({ editTeacher: false }) */
        axios
            .post("/api/principals/edit/teacher", data)
            .then((res) => {
                this.setState({teacherToEdit: {}, editTeacher: false});
            })
            .catch((err) => console.log(err));
    };

    stopEdit = () => {
        this.setState({editTeacher: false, teacherToEdit: {}});
    };

    handleRemove = (e) => {
        e.preventDefault();
        this.props.deleteTeacher(e.currentTarget.id);
    };

    activate = (e) => {
        const data = {
            email: e.target.id,
        };
        this.props.activateTeacher(data);
        this.props.alert.success(
            this.state.language.fi ? "Opettaja aktivoitu" : "Lärare aktiverad"
        );
    };

    deactivate = (e) => {
        const data = {
            email: e.target.id,
        };
        this.props.deactivateTeacher(data);
        this.props.alert.show(
            this.state.language.fi ? "Opettaja deaktivoitu" : "Lärare inaktiverad"
        );
    };

    handleUserClick = (e) => {
        console.log(e.target.id);
        /* alert(e.target.id) */
    };

    onLogoutClick = () => {
        this.setState({
            isUser: false,
            isTeacher: false,
            isPrincipal: false,
        });
        this.props.logoutTeacher();
    };

    showSidebar = () => {
        const {sidebar} = this.state;
        if (sidebar) {
            this.setState({sidebar: false});
        } else if (!sidebar) {
            this.setState({sidebar: true});
        }
    };

    render() {
        const {teacher} = this.props.auth;
        const imgUrl = `${window.location.origin}/api/shared/image/`;
        const {
            teachers,
            listSort,
            isLoading,
            sidebar,
            language,
            grades,
            editTeacher,
            teacherToEdit,
            lastName,
            firstName,
            email,
            phone,
            preschool,
            codeInfo,
        } = this.state;
        return (
            <>
                <div className="temp-body">
                    <CustomNavbar
                        userType={teacher.typeOfUser}
                        sidebar={sidebar}
                        showSidebar={this.showSidebar}
                        page={language.teachers2}
                        user={teacher}
                        logoutTeacher={this.onLogoutClick}
                        isAuth={this.props.auth.isAuthenticated}
                    />
                    <Sort
                        header={language.subheading_teachers}
                        listOrBlock={this.listOrBlock}
                        handleSort={this.handleSort}
                        userType={teacher.typeOfUser}
                        marginLeft={sidebar ? "215px" : "16px"}
                        school={teacher.school}
                    />
                    <hr
                        style={{marginTop: "60px", position: "fixed", zIndex: "9"}}
                        className="custom-hr"
                    ></hr>
                    <div className={sidebar ? "main push-right" : "main"}>
                        <SchoolCodePage
                            language={language}
                            multiple={
                                teacher?.moreSchools && teacher?.moreSchools?.length > 0
                            }
                            codeInfo={codeInfo}
                        />
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            style={{fontSize: "1.8em", fontWeight: "600"}}
                        />
                        {!editTeacher ? (
                            <Container fluid>
                                {!isLoading ? (
                                    <Row className="mt-3">
                                        {!listSort
                                            ? teachers?.map((teacher) => (
                                                <Col
                                                    xl={3}
                                                    lg={4}
                                                    md={6}
                                                    sm={12}
                                                    key={teacher.email}
                                                    className="mb-3"
                                                >
                            <span
                                style={{
                                    fontSize: "1.8em",
                                    fontWeight: "600",
                                    color: "#000000c4",
                                }}
                            >
                              {preschool && teacher.school}
                            </span>
                                                    <Card className="teacheradmin-block">
                                                        <Row>
                                                            <Card.Body
                                                                style={{
                                                                    marginBottom: "0",
                                                                    paddingBottom: "0",
                                                                }}
                                                            >
                                                                <Card.Img
                                                                    rounded="true"
                                                                    variant="left"
                                                                    className="img-size"
                                                                    src={
                                                                        teacher.image
                                                                            ? imgUrl + teacher.image
                                                                            : DefaultPhoto
                                                                    }
                                                                />
                                                                <Dropdown className="dots-menu">
                                                                    <Dropdown.Toggle
                                                                        as={this.customToggle}
                                                                        id="dd-custom-toggle"
                                                                    ></Dropdown.Toggle>
                                                                    <Dropdown.Menu style={{zIndex: "10"}}>
                                                                        <Dropdown.Item
                                                                            className="dd-item"
                                                                            id={teacher.email}
                                                                            onClick={this.handleEdit}
                                                                            eventKey="1"
                                                                        >
                                                                            <img
                                                                                alt="edit-svg-icon"
                                                                                src={EditIcon}
                                                                                className="mr-2"
                                                                            />
                                                                            {language.editdropdwn}
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item
                                                                            className="dd-item"
                                                                            id={teacher.email}
                                                                            onClick={this.handleRemove}
                                                                            eventKey="2"
                                                                        >
                                                                            <img
                                                                                alt="remove-svg-icon"
                                                                                src={RemoveIcon}
                                                                                className="mr-2"
                                                                            />
                                                                            {language.remove}
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                                <Card.Title
                                                                    style={{
                                                                        fontWeight: "600",
                                                                        fontSize: "1.6em",
                                                                        color: "#2e2f31",
                                                                    }}
                                                                    className="mb-0 mt-2"
                                                                >
                                                                    {teacher.firstName} {teacher.lastName}
                                                                </Card.Title>
                                                                <Card.Text
                                                                    style={{fontSize: "1.4em"}}
                                                                    className="mb-2"
                                                                >
                                                                    {language.grade}:{" "}
                                                                    {teacher.grade.join(", ") ||
                                                                        language.noinfo}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Row>
                                                        <Row className="justify-content-sm-center">
                                                            <hr className="custom-hr-2"/>
                                                        </Row>
                                                        <Row className="mx-auto">
                                                            <Card.Body
                                                                style={{
                                                                    display: "inline-block",
                                                                    textAlign: "left",
                                                                    padding: "0px 0px 10px 0px",
                                                                }}
                                                            >
                                                                <Card.Text
                                                                    style={{
                                                                        fontSize: "1.4em",
                                                                        color: "#707070",
                                                                        marginBottom: "0.3em",
                                                                    }}
                                                                    className="mb-0"
                                                                >
                                                                    <img
                                                                        alt="phone-svg-icon"
                                                                        src={PhoneIcon}
                                                                        className="mr-1 ml-2"
                                                                    />
                                                                    {teacher.phone}
                                                                </Card.Text>
                                                                <Card.Text
                                                                    style={{
                                                                        fontSize: "1.4em",
                                                                        color: "#707070",
                                                                        marginBottom: "0.3em",
                                                                    }}
                                                                    className="mb-0"
                                                                >
                                                                    <img
                                                                        alt="email-svg-icon"
                                                                        src={EmailIcon}
                                                                        className="mr-1 ml-2"
                                                                    />
                                                                    {teacher.email}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Row>
                                                        <Card.Footer>
                                                            {teacher.approved ? (
                                                                <Button
                                                                    className="mt-auto"
                                                                    variant="active"
                                                                    id={teacher.email}
                                                                    onClick={this.deactivate}
                                                                >
                                                                    {language.activebtn}
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className="mt-auto"
                                                                    onClick={this.activate}
                                                                    id={teacher.email}
                                                                    variant="deactive"
                                                                >
                                                                    {language.notactivebtn}
                                                                </Button>
                                                            )}
                                                        </Card.Footer>
                                                    </Card>
                                                </Col>
                                            ))
                                            : teachers?.map((teacher) => (
                                                <Col md={12} key={teacher.email} className="mt-3">
                                                    <Card className="long-card align-middle">
                                                        <Container className="image-container">
                                                            <Card.Img
                                                                variant="left"
                                                                className="img-size"
                                                                src={
                                                                    teacher?.image
                                                                        ? imgUrl + teacher.image
                                                                        : DefaultPhoto
                                                                }
                                                            />
                                                        </Container>
                                                        <Col>
                                                            <Card.Body className="no-padding">
                                                                <Card.Title
                                                                    className="mb-0"
                                                                    style={{
                                                                        fontWeight: "600",
                                                                        fontSize: "1.6em",
                                                                        color: "#2e2f31",
                                                                    }}
                                                                >
                                                                    {teacher.firstName} {teacher.lastName}
                                                                </Card.Title>
                                                                <Card.Text
                                                                    style={{fontSize: "1.4em"}}
                                                                    className="mb-0"
                                                                >
                                                                    {language.grade}:{" "}
                                                                    {teacher.grade || language.noinfo}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Col>
                                                        <Col>
                                                            <Card.Body className="custom-body">
                                                                <Card.Text
                                                                    className=""
                                                                    style={{
                                                                        fontSize: "1.4em",
                                                                        color: "#707070",
                                                                        marginBottom: "0.3em",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={Location}
                                                                        alt="location"
                                                                        className="mr-2"
                                                                    />{" "}
                                                                    {teacher.address}
                                                                </Card.Text>
                                                                <Card.Text
                                                                    style={{
                                                                        fontSize: "1.4em",
                                                                        color: "#707070",
                                                                        marginBottom: "0.3em",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={Phone}
                                                                        alt="phone"
                                                                        className="mr-2"
                                                                    />{" "}
                                                                    +358 {teacher.phone}
                                                                </Card.Text>
                                                                <Card.Text
                                                                    className="mb-0"
                                                                    style={{
                                                                        fontSize: "1.4em",
                                                                        color: "#707070",
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={Mail}
                                                                        alt="mail"
                                                                        className="mr-2"
                                                                    />{" "}
                                                                    {teacher.email}
                                                                </Card.Text>
                                                            </Card.Body>
                                                        </Col>
                                                        <Container
                                                            id={teacher.email}
                                                            className="arrow-container align-middle"
                                                            onClick={this.handleUserClick}
                                                        >
                                                            <FontAwesomeIcon icon={faChevronRight}/>
                                                        </Container>
                                                    </Card>
                                                </Col>
                                            ))}
                                    </Row>
                                ) : (
                                    <div className="spin-wrapper">
                                        <Spinner size="8x" spinning={"spinning"}/>
                                    </div>
                                )}
                                <div style={{display: "flex"}}>
                                    <Button
                                        className="mr-2"
                                        style={{marginLeft: "auto"}}
                                        onClick={() => {
                                            this.props.activateAllTeachers({
                                                school: this.props.auth.teacher.school,
                                            });
                                            /* alert.success(language.teacheractivated) */
                                            toast.success(language.teacheractivated, {
                                                toastId: "supportsent",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            });
                                        }}
                                        variant="custom"
                                    >
                                        {language.activate}
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            this.props.deactivateAllTeachers({
                                                school: this.props.auth.teacher.school,
                                            });
                                            /*  alert.error(language.teacherdeactivated) */
                                            toast.error(language.teacherdeactivated, {
                                                toastId: "supportsent",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "light",
                                            });
                                        }}
                                        variant="customDelete-reverse"
                                    >
                                        {language.deactivateteachers}
                                    </Button>
                                </div>
                            </Container>
                        ) : (
                            <EditTeacher
                                language={language}
                                onChange={this.onChange}
                                onSubmit={this.onSubmit}
                                teacher={teacherToEdit}
                                firstName={firstName}
                                lastName={lastName}
                                email={email}
                                phone={phone}
                                cancel={this.stopEdit}
                                setgrades={this.setGrades}
                                grades={grades}
                            />
                        )}
                    </div>
                </div>
            </>
        );
    }
}

TeacherAdmin.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    logoutTeacher: PropTypes.func.isRequired,
    activateTeacher: PropTypes.func.isRequired,
    deactivateTeacher: PropTypes.func.isRequired,
    deleteTeacher: PropTypes.func.isRequired,
    deactivateAllTeachers: PropTypes.func.isRequired,
    activateAllTeachers: PropTypes.func.isRequired,
    getSchoolCodes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    logoutTeacher,
    activateTeacher,
    deactivateTeacher,
    deleteTeacher,
    deactivateAllTeachers,
    activateAllTeachers,
    getSchoolCodes,
})(withRouter(withAlert()(TeacherAdmin)));
